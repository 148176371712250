<template>
  <div>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
      v-model="searchQuery"
      @keyup="handleSearch"
      :append-icon="icons.mdiMagnify"
      placeholder="Buscar Tutor..."
      outlined
      hide-details
      dense
      class="user-search me-3 mb-4"
      >

      </v-text-field>

      <v-spacer></v-spacer>
      
      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
      <v-btn
        color="primary"
        class="mb-4 me-3"
        @click="$refs.tutors_form_modal.openModal()"
        >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
      <span>Agregar Tutor</span>
      </v-btn>

    </div>
    </v-card-text>

    <!-- Table -->
    <v-data-table
      :headers="tableColumns"
      :items="tutorsListTable"
      :search="search"
      :page="current_page"
      :server-items-length="totalTutorsListTable"
      @update:page="changingTutorsPage($event)"
      @update:items-per-page="changingTutorsItemsPerPage($event)"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, { value: this.totalTutorsListTable, text: 'Todos' }],
        'items-per-page-text': 'Items por página',
        'page-text': '{0}-{1} de {2}'
      }"
      class="table-kitchen-sink"
    >
      <!-- NO data -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No se encontraron
            <strong>tutores</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <!-- Nombre -->
      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <!-- Patients -->
      <template v-slot:item.patients="{ item }">
        <div v-if="item.patients.length">
          <v-chip
            v-for="(patient, index) in item.patients"
            class="ms-1"
            :key="index"
            x-small
            outlined
          >
            {{ patient.name }}
          </v-chip>
        </div>
        <div v-else class="ms-6"> - </div>
      </template>

      <!-- Adress -->
      <template #[`item.address`]="{item}">
        {{ `${item.address.length ? item.address : '-'}` }}
      </template>

      <!-- Phone -->
      <template #[`item.phone`]="{item}">
        {{ `${item.phone.length ? item.phone : '-'}` }}
      </template>

      <!-- Email -->
      <template #[`item.email`]="{item}">
        {{ `${item.email.length ? item.email : '-'}` }}
      </template>
    
    <!-- action -->
    <template #[`item.actions`]="{ item }">
      <v-icon
      class="me-2"
      @click="$refs.tutors_form_modal.openModal(item.id)"
      color="primary"
      >
       {{ icons.mdiPencilOutline }}
      </v-icon>
      <v-icon
      @click="deleteModal(item)"
      color="error"
      >
       {{ icons.mdiDeleteOutline }}
      </v-icon>
    </template>
      
    </v-data-table>

    <!-- DELETE dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      >
      <v-card >
        <v-card-title>
        Eliminar Tutor? <b class="ps-3">{{ `'${editedItem.name}'` }}</b>
        </v-card-title>
        
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="closeDelete"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          @click="deleteTutor"
        >
          Eliminar
        </v-btn>
        <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TutorsFormModal 
    ref="tutors_form_modal"
    @display-alert="displayAlert"
    @reload-tutors-list="getTutorsList()"
    />

    <AlertPopUp ref="alert_pop_up"/>

  </div>
</template>
  
<script>
import { ref } from '@vue/composition-api'
import TutorsFormModal from './TutorsFormModal.vue';
import debounce from '../../plugins/debounce.js'
import AlertPopUp from '../AlertPopUp.vue';
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline
} from '@mdi/js'

export default {
  data: ()  => {
	return {
    searchQuery: '',
    page_size: 10,
    current_page: 1,
    patientsList: [],
    loading: false,
    tutorsList: [],
    dialog:false,
	  dialogDelete:false,
	  editedIndex: -1,
    editedItem: {},
    defaultItem: {},
	}
  },
  methods: {
    changingTutorsPage(e) {
      this.current_page = e
      this.getTutorsList()
    },
    changingTutorsItemsPerPage(e) {
      this.page_size = e
      this.getTutorsList()
    },
    handleSearch: debounce(function() {
      this.current_page = 1;
      this.getTutorsList();
    }, 400),
    async getTutorsList(){
      this.loading = true
      try {
        const data = {
          page_size: this.page_size, 
          page: this.current_page,
          search: this.searchQuery,
          summary: false,
        }
        const response = await this.$api.getTutorsList(data)

        this.tutorsListTable = response.results
        this.totalTutorsListTable = response.count
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
	
    },
    deleteModal(item) {
      this.editedIndex = this.tutorsList.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
	 
      },
    async deleteTutor() { 
      try {
        await this.$api.deleteTutor(this.editedItem.id)
        this.getTutorsList()
        this.$refs.alert_pop_up.displayAlert({ message: 'Tutor eliminado correctamente', type: 'success' })
        this.close()
      } catch (e) {
        console.log(e)
      }
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    //display alerts from childs
    displayAlert(message, type){
      this.$refs.alert_pop_up.displayAlert(message, type)
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
    },
    mounted() {
      this.getTutorsList()
      // console.log(this.getUserRole)
  },
  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {
    const tutorsListTable = ref([]);
    const totalTutorsListTable = ref(0);
  
    const tableColumns = [
      { text: 'NOMBRE', value: 'name', align: 'left', sortable: false },
      { text: 'DIRECCION', value: 'address', align: 'left', sortable: false },
      { text: 'TELEFONO', value: 'phone', align: 'left', sortable: false },
      { text: 'EMAIL', value: 'email', align: 'center', sortable: false },
      { text: 'PACIENTE', value: 'patients', align: 'left', sortable: false },
      { text: 'ACTIONS', value: 'actions', align: 'center', sortable: false, width: "100px"},
    ];

      
    const search = ref("");
    const statusColor = { Confirmed: "primary", Completed: "success", Cancelled: "error" };
      return {
        tableColumns,
        statusColor,
        tutorsListTable,
        totalTutorsListTable,
        icons: {
            mdiMagnify,
            mdiDeleteOutline,
            mdiPencilOutline
        },
        search,
    };
  },
  components: { TutorsFormModal, AlertPopUp }
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>