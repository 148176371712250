<template>
  <v-snackbar
    content-class="custom-alert-dialog"
    v-model="showAlert"
    :timeout="timeout"
    :color="color"
    elevation="5"
    transition="scale-transition"
    bottom
    right
    
    >
    <v-icon class="me-3 pb-1" color="#fff"> {{icon}}</v-icon>
    <span class="">{{ message }}</span>

    <template #action="{ attrs }">
      <v-icon
      color="#fff"
      v-bind="attrs"
      @click="showAlert = false"
      >
      {{icons.mdiClose}}
      </v-icon>
    </template>
  </v-snackbar>
</template>
  
<script>
import { 
  mdiClose,
  mdiInformation,
  mdiAlertOutline, 
  mdiCheckCircle
} from '@mdi/js'

export default {
  data: () => {
    return {
    showAlert: false,
    message: '',
    color: '',
    icon: '',
    timeout: 3000,
    }
  },
  methods: {
    displayAlert(payload){
      this.showAlert = true;
      this.message = payload.message;
      this.timeout = payload.timeout ? payload.timeout : 3000
      switch (payload.type) {
        case 'success':
          this.color = 'success';
          this.icon = this.icons.mdiCheckCircle;
          break;
        case 'error':
          this.color = 'error';
          this.icon = this.icons.mdiAlertOutline;
          break;
        case 'info':
          this.color = 'accent';
          this.icon = this.icons.mdiInformation;
          break;
        default:
          
          this.color = 'primary';
          this.icon = this.icons.mdiMagnify;
      }
    }
  },
  setup(){
    return {
      icons:{
        mdiClose,
        mdiInformation,
        mdiAlertOutline,
        mdiCheckCircle,
      }
    }
  }
}
</script>
  
<style>
  .custom-alert-dialog {
   margin-top: 3px !important;
   font-size: 1rem;
   font-weight: 400;
  }

</style>