<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>Tutores</v-card-title>
        <ListTutors />
      </v-card>
  
    </div>
  </template>
  
  <script>
  
//   import UserList from '@/components/Users/UserList.vue';

import ListTutors from '@/components/Tutors/ListTutors.vue';

  export default {
   components: {
    ListTutors
},
   setup() {
      return {
        // icons
        icons: {
        },
      }
    },
  
  }
  </script>